// API
export const DOMAIN = "administrator.1toko.com";
export const BASE_URL = "https://api.admin.1toko.com/";
export const BASE_URL_IMG = "https://api.image.v2.1toko.com/";

// PARAM
export const BASE_YOUTUBE_LINK = "https://www.youtube.com/watch?v="

// REGEX
const regex = (reg) => new RegExp(reg);
export const regexNumber = regex(`^([0-9])+$`);